exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dev-404-page-tsx": () => import("./../../../src/pages/dev-404-page.tsx" /* webpackChunkName: "component---src-pages-dev-404-page-tsx" */),
  "component---src-templates-bar-page-index-tsx": () => import("./../../../src/templates/BarPage/index.tsx" /* webpackChunkName: "component---src-templates-bar-page-index-tsx" */),
  "component---src-templates-beach-page-index-tsx": () => import("./../../../src/templates/BeachPage/index.tsx" /* webpackChunkName: "component---src-templates-beach-page-index-tsx" */),
  "component---src-templates-boat-tour-page-index-tsx": () => import("./../../../src/templates/BoatTourPage/index.tsx" /* webpackChunkName: "component---src-templates-boat-tour-page-index-tsx" */),
  "component---src-templates-business-page-index-tsx": () => import("./../../../src/templates/BusinessPage/index.tsx" /* webpackChunkName: "component---src-templates-business-page-index-tsx" */),
  "component---src-templates-contacts-page-index-tsx": () => import("./../../../src/templates/ContactsPage/index.tsx" /* webpackChunkName: "component---src-templates-contacts-page-index-tsx" */),
  "component---src-templates-deal-page-index-tsx": () => import("./../../../src/templates/DealPage/index.tsx" /* webpackChunkName: "component---src-templates-deal-page-index-tsx" */),
  "component---src-templates-deals-page-index-tsx": () => import("./../../../src/templates/DealsPage/index.tsx" /* webpackChunkName: "component---src-templates-deals-page-index-tsx" */),
  "component---src-templates-gallery-page-index-tsx": () => import("./../../../src/templates/GalleryPage/index.tsx" /* webpackChunkName: "component---src-templates-gallery-page-index-tsx" */),
  "component---src-templates-home-page-index-tsx": () => import("./../../../src/templates/HomePage/index.tsx" /* webpackChunkName: "component---src-templates-home-page-index-tsx" */),
  "component---src-templates-la-serra-page-index-tsx": () => import("./../../../src/templates/LaSerraPage/index.tsx" /* webpackChunkName: "component---src-templates-la-serra-page-index-tsx" */),
  "component---src-templates-location-page-index-tsx": () => import("./../../../src/templates/LocationPage/index.tsx" /* webpackChunkName: "component---src-templates-location-page-index-tsx" */),
  "component---src-templates-policy-page-index-tsx": () => import("./../../../src/templates/PolicyPage/index.tsx" /* webpackChunkName: "component---src-templates-policy-page-index-tsx" */),
  "component---src-templates-pool-page-index-tsx": () => import("./../../../src/templates/PoolPage/index.tsx" /* webpackChunkName: "component---src-templates-pool-page-index-tsx" */),
  "component---src-templates-remmese-page-index-tsx": () => import("./../../../src/templates/RemmesePage/index.tsx" /* webpackChunkName: "component---src-templates-remmese-page-index-tsx" */),
  "component---src-templates-room-page-index-tsx": () => import("./../../../src/templates/RoomPage/index.tsx" /* webpackChunkName: "component---src-templates-room-page-index-tsx" */),
  "component---src-templates-rooms-page-index-tsx": () => import("./../../../src/templates/RoomsPage/index.tsx" /* webpackChunkName: "component---src-templates-rooms-page-index-tsx" */),
  "component---src-templates-services-page-index-tsx": () => import("./../../../src/templates/ServicesPage/index.tsx" /* webpackChunkName: "component---src-templates-services-page-index-tsx" */),
  "component---src-templates-spa-page-index-tsx": () => import("./../../../src/templates/SpaPage/index.tsx" /* webpackChunkName: "component---src-templates-spa-page-index-tsx" */),
  "component---src-templates-weddings-page-index-tsx": () => import("./../../../src/templates/WeddingsPage/index.tsx" /* webpackChunkName: "component---src-templates-weddings-page-index-tsx" */)
}

